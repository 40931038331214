import './PolyFills';
import $ from 'jquery';
import M from 'materialize-css';
import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import { TimelineMax, TweenMax } from 'gsap';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import HomePage from './pages/HomePage';
import Footer from './components/Footer';
import Header from './components/Header';
import ClubDetailPage from './pages/ClubDetailPage';
import AboutPage from './pages/AboutPage';

$(function () {
    Swiper.use([Navigation, Pagination, Autoplay]);
    M.Collapsible.init(document.querySelectorAll('.collapsible'));
    M.FormSelect.init(document.querySelectorAll('select'));
    ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

    createHeader();
    createFooter();
    createImgBackground();
    createNewsSlider();
    createRelateSlider();
    createPage();

    $(window).on('resize', onWindowResize);
    onWindowResize();

    window.onscroll = () => {
        onWindowScroll();
    };
    onWindowScroll();

    $(window).scrollTop(0);
});

function onWindowResize() {
    renderElements.map((element) => {
        element.onResize();
        element.onScroll(window.pageYOffset);
    });
}

function onWindowScroll() {
    renderElements.map((element) => {
        element.onScroll(window.pageYOffset);
    });
}

function createImgBackground() {
    const imageList = document.querySelectorAll('.img-cover');
    [...imageList].map((image) => {
        const parentNode = image.parentNode;
        parentNode.style.backgroundImage = `url(${image.src})`;
        parentNode.style.backgroundSize = 'cover';
        parentNode.style.backgroundPositionX = 'center';
        parentNode.style.backgroundPositionY = 'center';
        parentNode.style.backgroundRepeat = 'no-repeat';
        image.style.display = 'none';
    });
}

const renderElements = [];

function createHeader() {
    if (document.contains(document.getElementById('header'))) {
        const header = new Header(document.getElementById('header'));
        header.init();
        header.emitter.on('ON_TOGGLE_MENU', (params) => {
            const htmlEl = document.getElementsByTagName('html')[0];
            if (params.isOpen) {
                htmlEl.style.overflowX = 'hidden';
                htmlEl.style.overflowY = 'hidden';
            } else {
                htmlEl.style.overflowX = 'hidden';
                htmlEl.style.overflowY = 'auto';
            }
        });
        renderElements.push(header);
    }
}

function createFooter() {
    if (document.contains(document.getElementById('footer'))) {
        const footer = new Footer(document.getElementById('footer'));
        footer.init();
        renderElements.push(footer);

        const topBtn = document.getElementById('footer').querySelector('.topBtn');
        topBtn.addEventListener('click', (e) => {
            e.stopPropagation();
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        });

        const copyrightYear = document.getElementById('footer').querySelector('.year');
        copyrightYear.innerHTML=new Date().getFullYear();
    }
}

function createNewsSlider() {
    if (document.contains(document.querySelector('.news-section'))) {
        const section = document.querySelector('.news-section');
        const sliders = section.querySelectorAll('.newsSlider');
        const prev = section.querySelector('.swiper-button-prev');
        const next = section.querySelector('.swiper-button-next');
        [...sliders].map((slider) => {
            new Swiper(slider, {
                slidesPerView: 'auto',
                spaceBetween: 20,
                autoplay: false,
                loop: false,
                navigation: {
                    prevEl: prev,
                    nextEl: next,
                },
                breakpoints: {
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 0,
                    },
                },
            });
        });
    }
}

function createRelateSlider() {
    if (document.contains(document.querySelector('.related-section'))) {
        const relateSection = document.querySelector('.related-section');
        const relateSliderList = relateSection.querySelectorAll('.relateSlider');
        const prev = relateSection.querySelector('.swiper-button-prev');
        const next = relateSection.querySelector('.swiper-button-next');
        [...relateSliderList].map((slider) => {
            new Swiper(slider, {
                slidesPerView: 2,
                spaceBetween: 20,
                navigation: {
                    nextEl: next,
                    prevEl: prev,
                },
                breakpoints: {
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    1000: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                },
            });
        });
    }
}

function createPage() {
    if (document.contains(document.querySelector('.homePage'))) {
        const homePage = new HomePage(document.querySelector('.homePage'));
        homePage.init();
        renderElements.push(homePage);
    }
    if (document.contains(document.querySelector('.aboutPage'))) {
        const aboutPage = new AboutPage(document.querySelector('.aboutPage'));
        aboutPage.init();
    }
    if (document.contains(document.querySelector('.clubDetailPage'))) {
        const clubDetailPage = new ClubDetailPage(document.querySelector('.clubDetailPage'));
        clubDetailPage.init();
    }
}
