import AbstractElement from "../components/AbstractElement";
import Swiper, {Navigation, Pagination, Autoplay, Thumbs} from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Pagination, Autoplay, Thumbs]);


class ClubDetailPage extends AbstractElement {
    constructor(pageEl) {
        super(pageEl);
    }

    init() {
        this.createModal();
    }


    createModal() {
        const galleryThumbs = new Swiper('.gallery-thumbs', {
            slidesPerView: 4,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
                640: {
                    slidesPerView: 4,
                },
                1200: {
                    slidesPerView: 6,
                    spaceBetween: 0
                }
            }
        });
        const gallery = new Swiper('.gallery', {
            slidesPerView: 1,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            thumbs: {
                swiper: galleryThumbs
            }
        });

        const elems = this.selfEl.querySelectorAll('.modal');
        const instances = M.Modal.init(elems, {
            inDuration: 500,
            onOpenStart: () => {
                gallery.update();
                galleryThumbs.update();
            },
            onOpenEnd: () => {
                gallery.update();
                galleryThumbs.update();
                setTimeout(() => {
                    const modalContent = this.selfEl.querySelector('.modal-content');
                    modalContent.classList.add('active');
                }, 10);
            }
        });
    }
}

export default ClubDetailPage;