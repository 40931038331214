import AbstractElement from '../components/AbstractElement';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import _ from 'underscore';

Swiper.use([Navigation, Pagination, Autoplay]);

import * as youtube from 'youtube-iframe-player';
import ScrollMagic from 'scrollmagic';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import { TweenMax, Quart } from 'gsap';
import { isValueEmpty } from '../utils/Utils';

class HomePage extends AbstractElement {
    constructor(htmlEl) {
        super(htmlEl);

        this.isFirefox = /Firefox/i.test(window.navigator.userAgent);
        this.isIe = /MSIE/i.test(window.navigator.userAgent) || /Trident.*rv\:11\./i.test(window.navigator.userAgent);

        this.slideDirection = 'prev';
        this.isScrolMode = false;
        this.ticking = false;
        this.scrollSensitivitySetting = 30;
        this.slideDurationSetting = 600;
        this.currentSlideNumber = 0;
        this.totalSlideNumber = this.selfEl.getElementsByClassName('parallax-section').length;
        this.parallaxSliders = [];
        this.lastSlider = null;
        this.onlyDesktop = false;
        this.clubEndTop = 0;
        this.clubScrollScene = null;
        this.bannerSlider = null;
    }

    init() {
        super.init();
        this.clubId = 0;
        this.bannerSection = this.selfEl.querySelector('.page-head');
        this.clubSection = this.selfEl.querySelector('.clubs-section');
        this.youtueList = [];
        this.youtueCoverList = [];
        this.youtueElList = [];
        this.coverSliderList = [];
        this.createClub();
        this.createBanner();
        this.controller = new ScrollMagic.Controller();
        this.createParallaxClubs();
        this.createParallax();
    }

    onResize() {
        this.onlyDesktop = window.innerWidth >= 1200;
        if (window.innerWidth >= 1200 && this.clubScrollScene) {
            this.clubScrollScene.enabled(true);
        } else {
            this.clubScrollScene.enabled(false);
            const clubsSection = this.selfEl.querySelector('.clubs-section');
            TweenMax.set(clubsSection, { top: 0 });
        }
    }
    onScroll(offsetTop) {
        if (offsetTop === 0) {
            this.isScrolMode = false;
        } else {
            this.isScrolMode = true;
        }
    }
    createParallax() {
        this.parallaxSliders = this.selfEl.querySelectorAll('.parallax-section');
        const mousewheelEvent = this.isFirefox ? 'DOMMouseScroll' : 'wheel';
        window.addEventListener(mousewheelEvent, _.throttle(this.onParallaxScroll.bind(this), 60), false);
    }
    onParallaxScroll(e) {
        if (!this.onlyDesktop) return;
        if (this.isScrolMode) return;
        const delta = this.isFirefox ? e.detail * -120 : this.isIe ? -e.deltaY : e.wheelDelta;
        if (!this.ticking) {
            if (delta <= -this.scrollSensitivitySetting) {
                //Down scroll
                if (this.currentSlideNumber < this.totalSlideNumber - 1) {
                    this.ticking = true;
                    this.currentSlideNumber++;
                    this.nextItem();
                    this.slideDurationTimeout(this.slideDurationSetting);
                    this.slideDirection = 'next';
                }
            }
            if (delta >= this.scrollSensitivitySetting) {
                //Up scroll
                if (this.currentSlideNumber > 0) {
                    this.ticking = true;
                    this.currentSlideNumber--;
                    this.previousItem();
                    this.slideDurationTimeout(this.slideDurationSetting);
                    this.slideDirection = 'prev';
                }
            }
            this.setCurrentItem();
        }
    }

    slideDurationTimeout(slideDuration) {
        const timeout = window.setTimeout(() => {
            this.ticking = false;
            window.clearTimeout(timeout);
        }, slideDuration);
    }

    nextItem() {
        const $previousSlide = [...this.parallaxSliders].find((slider, index) => {
            return index === this.currentSlideNumber - 1;
        });
        $previousSlide.classList.remove('up-scroll');
        $previousSlide.classList.add('down-scroll');
    }

    setCurrentItem() {
        [...this.parallaxSliders].map((slide, index) => {
            if (index === this.currentSlideNumber) {
                slide.classList.add('currentSlide');
            } else {
                slide.classList.remove('currentSlide');
            }
        });

        const main = this.selfEl.querySelector('.main');
        if (this.currentSlideNumber === this.parallaxSliders.length - 1) {
            const lastSlide = this.selfEl.querySelector('.currentSlide');
            lastSlide.addEventListener(
                'transitionend',
                () => {
                    if (this.slideDirection === 'next') {
                        main.classList.add('scroll-mode');
                        lastSlide.classList.add('scroll-mode');
                    } else {
                        lastSlide.classList.remove('scroll-mode');
                    }
                },
                true
            );
        } else {
            this.isScrolMode = false;
            main.classList.remove('scroll-mode');
        }
    }

    previousItem() {
        const $currentSlide = [...this.parallaxSliders].find((slider, index) => {
            return index === this.currentSlideNumber;
        });
        $currentSlide.classList.remove('down-scroll');
        $currentSlide.classList.add('up-scroll');
    }

    createMarquee() {
        const marqueeList = this.selfEl.querySelectorAll('.message-alert');
        [...marqueeList].map((item) => {
            const marquee = item.querySelector('.marquee');
            if (isValueEmpty(marquee.innerHTML)) {
                item.classList.remove('active');
            } else {
                item.classList.add('active');
            }
        });
    }

    /*
     * Parallax
     * */

    createParallaxClubs() {
        const clubsSection = this.selfEl.querySelector('.clubs-section');
        const clubsTween = TweenMax.fromTo('.clubs-section', 1, { top: 300 }, { top: 0, ease: Quart.easeOut });
        this.clubScrollScene = new ScrollMagic.Scene({
            triggerElement: '#triggerClubs',
            duration: clubsSection.clientHeight,
            triggerHook: 1,
        })
            .setTween(clubsTween)
            .addTo(this.controller);
    }

    /*
     * Banner
     * */
    createBanner() {
        const bannerSlideEl = this.selfEl.querySelector('.bannerSlider');
        const prev = bannerSlideEl.querySelector('.swiper-button-prev');
        const next = bannerSlideEl.querySelector('.swiper-button-next');

        this.bannerSlider = new Swiper(bannerSlideEl, {
            slidesPerView: 1,
            loop: true,
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
            on: {
                slideChange: (swiper) => {
                    this.resetBannerVideo();
                },
                touchStart: () => {},
                touchEnd: () => {},
            },
        });

        youtube.init(() => {
            const slideElList = bannerSlideEl.querySelectorAll('.swiper-slide');
            [...slideElList].map((slide, index) => {
                const hasYoutube = slide.querySelector('.youtube-player');
                if (hasYoutube) {
                    const coverEl = slide.querySelector('.video-cover');
                    coverEl.addEventListener('click', (event) => {
                        event.stopPropagation();
                        coverEl.classList.add('is-playing');
                        this.playVideo(index);
                    });
                    const videoEl = slide.querySelector('.video');
                    const playerEl = videoEl.querySelector('.youtube-player');
                    console.log('playerEl', playerEl.id);
                    const youtubePlayer = youtube.createPlayer(playerEl.id, {
                        width: '1280',
                        height: '720',
                        videoId: playerEl.dataset.video,
                        playerVars: {
                            autoplay: 0,
                            controls: 0,
                            mute: 1,
                            loop: 1,
                        },
                        events: {
                            onReady: (event) => {
                                if (index === 0) {
                                    event.target.pauseVideo();
                                } else {
                                    event.target.pauseVideo();
                                }
                            },
                            onStateChange: (event) => {
                                if (event.data === 2) {
                                    this.resetBannerVideo();
                                }
                                if (event.data === 0) {
                                    //ended
                                    event.target.seekTo(0);
                                    this.resetBannerVideo();
                                    this.bannerSlider.slideNext();
                                }
                            },
                        },
                    });
                    this.youtueCoverList.push(coverEl);
                    this.youtueList.push(youtubePlayer);
                    this.youtueElList.push(videoEl);
                } else {
                    this.youtueCoverList.push(null);
                    this.youtueList.push(null);
                    this.youtueElList.push(null);
                }
            });
        });
    }

    playVideo(id) {
        const currentPlayer = this.youtueList[id];
        currentPlayer.playVideo();

        this.bannerSlider.autoplay.stop();
        [...this.youtueElList].map((item, index) => {
            if (index === id) {
                item.classList.add('is-playing');
            }
        });

        const scrollHintEl = this.selfEl.querySelector('.scroll-hint');
        scrollHintEl.classList.add('is-playing');
    }

    resetBannerVideo() {
        const bannerSlideEl = this.selfEl.querySelector('.bannerSlider');
        const coverList = bannerSlideEl.querySelectorAll('.video-cover');
        [...coverList].map((cover) => {
            cover.classList.remove('is-playing');
        });
        this.youtueElList.map((elem) => {
            if (elem) {
                elem.classList.remove('is-playing');
            }
        });
        this.youtueList.map((player) => {
            if (player) {
                player.pauseVideo();
            }
        });

        if (this.bannerSlider) {
            this.bannerSlider.autoplay.start();
        }
        const scrollHintEl = this.selfEl.querySelector('.scroll-hint');
        scrollHintEl.classList.remove('is-playing');
    }

    /*
     * Location
     * */
    createClub() {
        const clubSelectEl = this.selfEl.querySelector('.clubSelect').getElementsByTagName('select')[0];
        clubSelectEl.addEventListener('change', () => {
            if (this.clubSlider) {
                this.clubSlider.slideTo(parseInt(clubSelectEl.value));
            }
        });
        const clubSliderEl = this.selfEl.querySelector('.clubSlider');
        const prev = this.clubSection.querySelector('.swiper-button-prev');
        const next = this.clubSection.querySelector('.swiper-button-next');
        this.clubSlider = new Swiper(clubSliderEl, {
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
            on: {
                slideChange: (swiper) => {
                    this.clubId = swiper.activeIndex;
                    clubSelectEl.value = this.clubId.toString();
                    this.activeThumbnails();
                },
            },
        });
        this.createClubThumbnails();
    }
    createClubCoverSlider() {
        const clubSliderEl = this.selfEl.querySelector('.clubSlider');
        const clubSliderList = clubSliderEl.querySelectorAll('.club-slide');
        [...clubSliderList].map((element) => {
            const coverSliderEl = element.querySelector('.coverSlider');
            const coverSlider = new Swiper(coverSliderEl, {
                slidesPerView: 1,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            });
            this.coverSliderList.push(coverSlider);
        });
        this.activeCoverSlider();
    }
    activeCoverSlider() {
        if (!this.coverSliderList.length) return;
        this.coverSliderList.map((slider, index) => {
            if (index === this.clubId) {
                slider.autoplay.delay = 1500;
                slider.autoplay.start();
            } else {
                slider.autoplay.stop();
            }
        });
    }
    createClubThumbnails() {
        const thumbnailList = this.clubSection.querySelectorAll('.thumbnail');
        [...thumbnailList].map((thumbnail, index) => {
            thumbnail.addEventListener('click', () => {
                if (this.clubSlider) {
                    this.clubSlider.slideTo(index);
                }
            });
        });
        this.activeThumbnails();
    }
    activeThumbnails() {
        const thumbnailList = this.clubSection.querySelectorAll('.thumbnail');
        [...thumbnailList].map((thumbnail, index) => {
            if (index === this.clubId) {
                thumbnail.classList.add('active');
            } else {
                thumbnail.classList.remove('active');
            }
        });
    }
}

export default HomePage;
