import AbstractElement from './AbstractElement';

class Header extends AbstractElement {
    constructor(pageEl) {
        super(pageEl);
        this.activeNav = false;
        this.toggleEl = null;
        this.offsetTop = null;
    }

    onResize(event) {
        super.onResize(event);
        if (window.innerWidth >= 1200) {
            this.activeNav = false;
            this.changeStatus();
        }
    }

    onScroll(offsetTop) {
        if (window.pageYOffset > this.offsetTop) {
            this.selfEl.classList.add('sticky');
        } else {
            this.selfEl.classList.remove('sticky');
        }
    }

    init() {
        super.init();
        this.offsetTop = this.selfEl.offsetTop;
        this.mobileNavEl = this.selfEl.querySelector('.nav-xs');
        this.stepperEl = this.mobileNavEl.querySelector('.list-stepper');
        this.toggleEl = this.selfEl.querySelector('.nav-toggle');
        this.createNav();
    }

    changeStatus() {
        if (this.toggleEl) {
            if (this.activeNav) {
                this.activeNav = true;
                this.toggleEl.classList.add('active');
            } else {
                this.activeNav = false;
                this.toggleEl.classList.remove('active');
            }
            this.emitter.emit('ON_TOGGLE_MENU', { isOpen: this.activeNav });
        }
        if (this.mobileNavEl) {
            if (this.activeNav) {
                this.mobileNavEl.classList.add('active');
            } else {
                this.mobileNavEl.classList.remove('active');
                if (this.stepperEl) {
                    this.stepperEl.classList.remove('active');
                }
            }
        }
    }

    createNav() {
        this.toggleEl.addEventListener('click', () => {
            this.activeNav = !this.activeNav;
            this.changeStatus();
            this.emitter.emit('ON_TOGGLE_MENU', { isOpen: this.activeNav });
        });
        const stepButtonEls = this.mobileNavEl.querySelectorAll('.stepButton');
        [...stepButtonEls].map((element, index) => {
            element.addEventListener('click', (event) => {
                this.activeSubNav(element.dataset.target);
            });
        });
        const backEl = this.mobileNavEl.querySelector('.backBtn');
        backEl.addEventListener('click', (event) => {
            this.stepperEl.classList.remove('active');
        });
    }

    activeSubNav(target) {
        const subNav = this.mobileNavEl.querySelector('.subNav');
        const groupEls = subNav.querySelectorAll('.nav-group');
        [...groupEls].map((element) => {
            const classes = [...element.classList];
            if (classes.includes(target)) {
                element.classList.add('active');
            } else {
                element.classList.remove('active');
            }
        });
        this.stepperEl.classList.add('active');
    }
}

export default Header;
