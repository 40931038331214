import AbstractElement from '../components/AbstractElement';

class AboutPage extends AbstractElement {
    constructor(htmlEl) {
        super(htmlEl);
    }

    init() {
        super.init();
    }
}

export default AboutPage;
