import AbstractElement from "./AbstractElement";

class Footer extends AbstractElement {
    constructor(pageEl) {
        super(pageEl);
    }
    init() {
        super.init();
        this.createCollapse();
    }
    onResize() {
        super.onResize();
        const toggleElList = this.selfEl.querySelectorAll('.collapse-toggle');
        [...toggleElList].map((element) => {
            const collapse = element.nextElementSibling;
            if ([...element.classList].includes('active')) {
                collapse.style.maxHeight = collapse.scrollHeight + "px";
            }
        });
    }
    createCollapse() {
        const toggleElList = this.selfEl.querySelectorAll('.collapse-toggle');
        [...toggleElList].map((element) => {
            const collapse = element.nextElementSibling;
            element.addEventListener('click', () => {
                if ([...element.classList].includes('active')) {
                    element.classList.remove('active');
                } else {
                    element.classList.add('active');
                }
                if (collapse.style.maxHeight) {
                    collapse.style.maxHeight = null;
                } else {
                    collapse.style.maxHeight = collapse.scrollHeight + "px";
                }
            });
        });
    }
}

export default Footer;