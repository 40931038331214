import EventEmitter from "eventemitter3";

class AbstractElement {
    constructor(htmlEl) {
        this.emitter = new EventEmitter();
        this.selfEl = htmlEl;
        this.init.bind(this);
    }
    onResize() {

    }
    onScroll(offsetTop) {

    }
    init() {

    }
}
export default AbstractElement;